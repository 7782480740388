/***************************************
 * Navy: #000080
 * Gainsboro: #DCDCDC
 * Red: #FF0000
 * Green: #008000
 * Cornsilk: #FFF8DC
 */
$v-navy: #000080;
$v-link: #800000;

body {
  font-family: Arial, sans-serif;
    margin-left: 0px;
    margin-top: 0px;
  }
    // font-size: 100%;
    // color: #000000;
    // font-weight: normal;
    // background-repeat: repeat-y;
    // background-image: url(../images/tt-back.gif);
// td.toc,
// .w-toc { width: 145px; }

td.rack-id,
.w-rack-id { width: 160px; }

td.pos { width: 65px; }

td.rack-type { width: 175px; }

td.full-date { width: 175px; }

td.small-date { width: 80px; }

td.container { width: 175px; }

td.container-tree {
  width: 250px;
}

td.button {
  width: 75px;
}

td.username {
  width: 80px;
}

td.spec-id {
  width: 130px;
}

td.number {
  width: 65px;
}

td.checkbox {
  width: 20px;
}

td.pos-xy {
  width: 25px;
}

td.rack-type-id {
  width: 100px;
}

td.container-id {
  width: 50px;
}

td.full-name {
  width: 130px;
}

td.department {
  width: 170px;
}

td.institution-name {
  width: 225px;
}

td.institution-id {
  width: 50px;
}

td.mobile-container {
  width: 50px;
}

td.track-event-info {
  width: 525px;
}

td.note {
  width: 300px;
}

td.container-rack-type-id {
  width: 275px;
}

.w-pos {
  width: 65px;
}

.w-rack-type {
  width: 175px;
}

.w-full-date {
  width: 175px;
}

.w-small-date {
  width: 80px;
}

.w-container {
  width: 175px;
}

.w-container-tree {
  width: 250px;
}

.w-mobile-container {
  width: 50px;
}

.w-button {
  width: 75px;
}

.w-username {
  width: 80px;
}

.w-spec-id {
  width: 130px;
}

.w-number {
  width: 65px;
}

.w-checkbox {
  width: 20px;
}

.w-pos-xy {
  width: 25px;
}

.w-rack-type-id {
  width: 100px;
}

.w-container-id {
  width: 50px;
}

.w-full-name {
  width: 130px;
}

.w-department {
  width: 170px;
}

.w-institution-name {
  width: 225px;
}

.w-institution-id {
  width: 50px;
}

.w-mobile-container {
  width: 50px;
}

.w-track-event-info {
  width: 525px;
}

.w-note {
  width: 300px;
}

.w-container-rack-type-id {
  width: 275px;
}

.w-icon {
  width: 35px;
}

.w-option-description {
  width: 300px;
}

.w-option-description-short {
  width: 150px;
}

.w-option-value {
  width: 450px;
}

.w-option-value-short {
  width: 275px;
}

.w-table-note {
  width: 520px;
}

.w-note-check {
  width: 136px;
}

td {
  font-size: 80%;
  vertical-align: top;
  text-align: left;
  /*	border: thin sold black; */
}

/* ---------------------------------
	Common styles from main tt.css
----------------------------------- */
div.action {
  font-size: 1.625em;
  text-align: center;
}

div.action-info {
  color: #008000;
  font-size: 1.625em;
  text-align: center;
  margin-bottom: 0.75rem;
}

div {
  &.search-result-head,
  &.page-section {
    margin-bottom: 15px;
    .title {
      color: $v-navy;
      font-size: 1.375em;
    }
  }
}

div.page-info {
  font-size: 1.25em;
}

table.maintable {
  min-width: 800px;
}
/* td.result-header {background-color: #DCDCDC; font-weight: bold; text-align: center;} */

font.add-new {
  color: $v-navy;
  font-size: 1.125em;
  font-weight: bold;
}

.search-error,
.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 12px;
  margin: 15px 0;
  border: 1px solid #ef9a9a;
  border-radius: 4px;
  font-size: 14px;
}

img,
input.button {
  border-style: none;
}

/*	Menu classes	*/
div.menu-section {
  a {
    text-decoration: none;
    font-size: 1.25em;
    font-weight: bold;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
}

div.menu-sub {
  font-size: 0.80em;
  text-indent: 26px;
}

div.menu-head {
  color: $v-navy;
  font-size: 1.25em;
  margin-top: 14px;
  margin-bottom: 0px;
  text-decoration: underline;
}

div.menu-topic {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  font-size: 1.0em;
  text-indent: 13px;
  margin-bottom: 0mm;
}

div.menu-section {
  margin-bottom: 20px;
}
a {color: $v-link;}

td.content {
  margin-left: 10px;
}
tr.admin-on {
  background-color: #FFF8DC;
}
tr.admin-off {
  background-color: transparent;
}

tr.locked-rack {
  color: $v-navy;
}

/*	Admin Open Rack Classes */
td.adm-or-user {
  text-align: center;
  background-color: #FFFFFF;
  border-bottom: 1px rgb(9, 80, 160) solid;
  border-top: 1px rgb(9, 80, 160) solid;
}

td.adm-or-head {
  background-color: #DCDCDC;
  border-bottom: 1px rgb(9, 80, 160) solid;
  border-right: 1px rgb(9, 80, 160) solid;
  border-left: 1px rgb(9, 80, 160) solid;
  border-top: 1px rgb(9, 80, 160) solid;
}

td.adm-or-rack {
  text-align: center;
  background-color: #FFFFFF;
  border-bottom: 1px rgb(9, 80, 160) solid;
  border-top: 1px rgb(9, 80, 160) solid;
}

td.adm-or-date {
  text-align: right;
  background-color: #E0FFFF;
  border-top: 1px rgb(9, 80, 160) solid;
  border-right: 1px rgb(9, 80, 160) solid;
}

td.adm-or-ses {
  text-align: left;
  background-color: #E0FFFF;
  border-top: 1px rgb(9, 80, 160) solid;
  border-left: 1px rgb(9, 80, 160) solid;
}

td.adm-or-instn {
  text-align: left;
  background-color: #E0FFFF;
  border-left: 1px rgb(9, 80, 160) solid;
  border-right: 1px rgb(9, 80, 160) solid;
}

td.adm-or-reason {
  text-align: right;
  background-color: #FFFFFF;
  border-left: 1px rgb(9, 80, 160) solid;
  border-right: 1px rgb(9, 80, 160) solid;
  border-top: 1px rgb(9, 80, 160) solid;
  border-bottom: 1px rgb(9, 80, 160) solid;
}

/*	Admin Classes */
font.admin-section-head {
  color: #008000;
  font-size: 1.125em;
  font-weight: bold;
}

font.admin-section-head-alt {
  color: $v-navy;
  font-size: 1.125em;
  font-weight: bold;
}

tr.result-header {
  background-color: #DCDCDC;
  font-weight: bold;
  text-align: center;
}

tr.result-header>td {
  text-align: center;
}

span.rse {
  color: #FF0000;
}

span.rsa {
  color: $v-navy;
}

table.datasection,
table.addobject,
table.modifyobject,
table.expire-rack {
  border: solid 1px black;
  border-spacing: 0px 0px;
}

tr.headrow td {
  background-color: #DCDCDC;
  text-align: center;
  color: #000000;
  padding-bottom: 3px;
  padding-top: 3px;
  border-bottom: solid 1px black;
  /*	border-top: solid 1px black; */
  /*	vertical-align: center; */
  font-weight: bold;
}

table.datasection caption,
table.addobject caption,
table.modifyobject caption {
  border-bottom: solid 1px black;
  /*	background-color: #DCDCDC; */
  background-color: #FFFFFF;
  font-size: 1.125em;
  text-align: left;
  color: $v-navy;
  padding-bottom: 7px;
}

table.datasection div.search-error {
  width: 550px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: bold;
}

td.add-new {
  font-size: 1.00em;
  color: $v-navy;
}

td.setting {
  color: #008000;
  font-weight: bold;
}

td.setting-heading {
  color: $v-navy;
  font-weight: bold;
}

td.center,
td.tc {
  text-align: center;
}

td.tr {
  text-align: right;
}

td.tb {
  font-weight: bold;
}

/*	Universal search classes	*/
/* --------------------------------------------------------- */
div.us-result-head {
  color: $v-navy;
  font-size: 1.375em;
  margin-bottom: 5px;
}

span.us-result-subhead {
  color: #000000;
  font-size: 0.75em;
  text-decoration: none;
}

span.search-term {
  color: #FF0000
}

table.us-results {
  border: solid 1px black;
  border-spacing: 0px;
  margin-bottom: 15px;
}

/* --------------------------------------------------------- */

/* --------------------------------------------------------- */
/*	Tracking Classes	*/
/* --------------------------------------------------------- */
span.track-date {
  color: $v-link;
}

span.track-action {
  color: $v-navy;
}

span.track-object {
  color: #FF0000;
}

span.track-action-by {
  color: #008000;
}

/* --------------------------------------------------------- */

/* --------------------------------------------------------- */
/*	Specimen Transfer Classes	*/
/* --------------------------------------------------------- */
span.std-destination-rack {
  color: $v-navy;
  font-size: 1.625em;
}

span.sti-destination-rack {
  color: #FF0000;
  font-size: 1.625em;
}

span.st-from-to {
  color: #008000;
  font-size: 1.375em;
}

span.st-from-to-info {
  color: $v-navy;
}

/* --------------------------------------------------------- */

.red {
  color: red;
}

.normal {
  color: black;
}

.button-group {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.settings-section {
  margin: 0.5rem 0;
  min-width: 600px;
}

.settings-caption {
  font-weight: bold;
  padding: 5px;
  background-color: #f0f0f0;
  border: 1px solid #000;
  border-bottom: none;
}

.settings-group {
  border: 1px solid #000;
}

.settings-row {
  display: flex;
  border-bottom: 1px solid #ccc;
  
  &:last-child {
    border-bottom: none;
  }
}

.settings-label {
  flex: 0 0 200px;
  padding: 8px;
  background-color: #f8f8f8;
  border-right: 1px solid #ccc;
  font-weight: bold;
}

.settings-value {
  flex: 1;
  padding: 8px;
  
  input[type="text"],
  input[type="number"] {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    margin: 0;
  }
  
  select {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
  }
}

.button-group {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  
  button {
    padding: 8px 16px;
    cursor: pointer;
  }
}

.error-message {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin: 0.5rem 0;
  border-radius: 2rem;
  background: #fa383e;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.success-message {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin: 0.5rem 0;
  border-radius: 2rem;
  background: #00a400;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.loading-message {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  margin: 0.5rem 0;
  border-radius: 2rem;
  background: #3578e5;  /* Docusaurus primary blue */
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.loading-message::before {
  content: '';
  width: 1em;
  height: 1em;
  border: 2px solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
